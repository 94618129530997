<template>
  <!-- Content -->
  <CRow>
    <Modal :modal="modal"></Modal>
    <CCol col="12">
      <CForm @submit.prevent="send">
        <!-- Back -->
        <CRow>
          <CCol lg="12">
            <div class="d-flex align-items-center justify-content-end mb-3">
              <a href="javascript:void(0)" @click="$router.go(-1)"
                ><CIcon name="cil-arrow-left" class="mr-2" />Voltar</a
              >
            </div>
          </CCol>
        </CRow>

        <!-- Fields -->
        <CCard>
          <CCardHeader
            class="d-flex justify-content-between align-items-center"
          >
            <h4 class="mb-0">
              {{ $route.params.id ? 'Editar Usuário' : 'Cadastrar Usuário' }}
            </h4>
            <div class="d-flex align-items-center">
              <div class="ml-2 d-flex align-items-center">
                <label class="mb-0"><b>Status</b></label>
                <CSwitch
                  type="checkbox"
                  color="success"
                  variant="3d"
                  class="mx-2"
                  v-bind="labelIcon"
                  :checked.sync="form.status"
                />
              </div>
            </div>
          </CCardHeader>

          <CCardBody>
            <CRow>
              <CCol lg="12" md="12" sm="12">
                <CRow>
                  <CCol lg="6" md="6" sm="12">
                    <CInput
                      v-model="form.name"
                      name="name"
                      :readonly="!pageInit"
                      label="Nome*"
                      placeholder="Nome do usuário"
                      required
                      :disabled="processing"
                    />
                  </CCol>
                  <CCol lg="6" md="6" sm="12">
                    <CInput
                      v-model="form.email"
                      name="email"
                      label="E-mail*"
                      :readonly="!pageInit"
                      type="email"
                      placeholder="exemplo@mail.com"
                      required
                      :disabled="processing"
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol lg="6" md="6" sm="12">
                    <CInput
                      v-model="form.password"
                      label="Senha*"
                      type="password"
                      name="password"
                      :readonly="!pageInit"
                      placeholder="************"
                      :required="$route.params.id ? false : true"
                      :disabled="processing"
                      @blur="checkConfirmationPassword"
                    />
                    <PasswordRequirements :password="form.password" />
                  </CCol>
                  <CCol lg="6" md="6" sm="12">
                    <CInput
                      id="confirm_password"
                      v-model="form.password_confirmation"
                      label="Confirmação da senha*"
                      type="password"
                      placeholder="************"
                      :readonly="!pageInit"
                      :required="$route.params.id ? false : true"
                      :is-valid="passwordErrors.confirmation"
                      invalid-feedback="Senhas senha incorreta"
                      :disabled="processing"
                      @blur="checkConfirmationPassword"
                    />
                    <div
                      v-if="differentPasswords && form.password_confirmation"
                      class="compare-passwords"
                      :class="differentPasswords ? 'different-passwords' : ''"
                    >
                      As senhas não conferem
                    </div>
                    <div
                      v-if="!differentPasswords && form.password_confirmation"
                      class="compare-passwords"
                      :class="!differentPasswords ? 'equal-passwords' : ''"
                    >
                      As senhas conferem
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <CCol lg="12" md="12" sm="12">
                <div class="form-group">
                  <label for="roles">Papéis*</label>
                  <Multiselect
                    id="roles"
                    v-model="form.roles"
                    :options="rolesOptions"
                    :multiple="true"
                    group-values="roles"
                    group-label="all"
                    :group-select="true"
                    :searchable="true"
                    :disabled="processing"
                    :required="true"
                    @input="setPermissions"
                  >
                    <span slot="noResult">Nenhum resultado encontrado</span>
                  </Multiselect>
                </div>
              </CCol>
            </CRow>
            <div class="permissions-container">
              <b class="permissions-title"> Permissões selecionadas </b>
              <CRow>
                <CCol
                  v-for="(permission, index) in selectedPermissions"
                  :key="index"
                  lg="3"
                >
                  <span class="selected-permissions-description">
                    {{ permission }}
                  </span>
                </CCol>
              </CRow>
              <CRow class="mt-4">
                <CCol
                  v-for="(permission, index) in notSelectedPermissions"
                  :key="index"
                  lg="3"
                >
                  <span class="not-selected-permissions-description">
                    {{ permission }}
                  </span>
                </CCol>
              </CRow>
            </div>
          </CCardBody>
        </CCard>

        <!-- Logs -->
        <CardLog v-if="$route.params.id" :log="log" />

        <!-- Actions -->
        <div class="form-actions">
          <a
            v-if="$route.params.id"
            href="javascript:void(0)"
            class="mr-3"
            @click="resetForm"
            >Limpar Alterações</a
          >
          <CInputCheckbox
            v-else
            label="Criar outro"
            class="mr-3"
            :disabled="processing"
            @update:checked="setRedirect"
          />
          <CButton color="success" type="submit" :disabled="processing">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </div>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>
import CardLog from '@/components/ui/CardLog'
import Modal from '@/components/ui/Modal'
import Multiselect from '@/components/ui/Multiselect'
import PasswordRequirements from '@/components/ui/PasswordRequirements'

import Service from '@/services/user.service'
import RoleService from '@/services/role.service'
import PermissionService from '@/services/permission.service'

import moment from 'moment'
import { cloneDeep, uniq, difference } from 'lodash'

export default {
  metaInfo: {
    title: 'Usuário',
    titleTemplate: '%s - Área Administrativa OW Interactive'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_users')) {
        vm.$router.push('/404')
      }
    })
  },

  components: {
    CardLog,
    Modal,
    Multiselect,
    PasswordRequirements
  },

  data() {
    return {
      user: {
        status: true,
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        roles: ''
      },
      form: {},
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      },
      log: {},
      passwordErrors: {
        confirmation: null
      },
      rolesOptions: [
        {
          all: 'Todos',
          roles: []
        }
      ],
      selectedRoles: [],
      processing: false,
      redirect: true,
      modal: {
        show: false,
        title: '',
        message: '',
        color: ''
      },
      allPermissions: '',
      selectedPermissions: '',
      notSelectedPermissions: '',
      pageInit: false
    }
  },

  computed: {
    differentPasswords: function () {
      let result
      const confirm_password = document.getElementById('confirm_password')
      if (confirm_password) {
        confirm_password.setCustomValidity('')
      }

      if (
        this.form.password_confirmation &&
        this.form.password !== this.form.password_confirmation
      ) {
        result = true
        confirm_password.setCustomValidity('As senhas não conferem')
      } else if (
        this.form.password_confirmation &&
        this.form.password === this.form.password_confirmation
      ) {
        result = false
        confirm_password.setCustomValidity('')
      }
      return result
    }
  },

  watch: {
    selectedRoles() {
      if (this.selectedRoles.length > 0) {
        this.form.roles = this.selectedRoles
      } else {
        this.form.roles = []
      }
    }
  },

  async created() {
    await this.getRolesOptions()

    if (this.$route.params.id) {
      const response = await Service.show(this.$route.params.id)

      this.user = {
        name: response.name,
        email: response.email,
        status: !!response.status,
        password: '',
        password_confirmation: ''
      }

      const roles = []

      // Set user roles
      response.roles.forEach((role) => {
        const selected = this.rolesOptions[0].roles.find(
          (userRole) => userRole.value === role.id
        )

        if (selected) {
          roles.push(selected)
        }
      })

      this.selectedRoles = roles

      this.log = {
        created_by: response.created ? response.created.name : 'Seed',
        created_at: moment(response.created_at).format('DD/MM/YYYY HH:mm'),
        updated_by: response.updated ? response.updated.name : null,
        updated_at: moment(response.updated_at).format('DD/MM/YYYY HH:mm')
      }
    }

    await this.setUser()
    await this.getAllPermissions()
    await this.setPermissions()
  },

  mounted() {
    setTimeout(() => {
      this.pageInit = true
    }, 1000)
  },

  methods: {
    async getRolesOptions() {
      const response = await RoleService.getAll()

      const rolesStored = response.map((role) => {
        return {
          label: role.name,
          value: role.id,
          permissions: role.permissions
        }
      })

      this.rolesOptions[0].roles = [...rolesStored]
    },

    checkConfirmationPassword() {
      if (
        this.user.password &&
        this.user.password !== this.user.password_confirmation
      ) {
        this.passwordErrors.confirmation = false
      } else {
        this.passwordErrors.confirmation = null
      }
    },

    setUser() {
      this.form = cloneDeep(this.user)
    },

    resetForm() {
      this.setUser()
    },

    setRedirect(event) {
      this.redirect = !event
    },

    roleChanged(value) {
      this.form.role = value
    },

    async send() {
      this.processing = true

      const data = { ...this.form, id: this.$route.params.id }
      data.roles = this.form.roles.map((role) => role.value)

      let response
      let message

      if (this.$route.params.id) {
        response = await Service.update(this.$route.params.id, data)
        message = `<b>${this.form.name}</b> foi atualizado com sucesso`
      } else {
        response = await Service.store(data)
        message = `<b>${this.form.name}</b> foi cadastrado com sucesso`
      }
      if (response.status === 200) {
        this.modal = {
          show: true,
          title: 'Sucesso',
          message: message,
          color: 'success',
          redirect: this.redirect
        }

        if (this.redirect) {
          setTimeout(() => {
            this.processing = false
            // this.$router.go(-1)
            this.$router.push(
              this.$route.matched[this.$route.matched.length - 2].path
            )
          }, 3000)
        } else {
          this.processing = false
          this.resetForm()
        }
      } else if (response.status === 400) {
        this.modal = {
          show: true,
          title: 'Erro',
          message: response.data[0].message || response.data,
          color: 'danger'
        }

        this.processing = false
      } else {
        this.modal = {
          show: true,
          title: 'Erro',
          message: 'Houve um erro ao salvar, por favor tente novamente',
          color: 'danger'
        }

        this.processing = false
      }
    },

    async getAllPermissions() {
      const result = await PermissionService.getAll()

      this.allPermissions = result.map((permission) => {
        return permission.description
      })
    },

    setPermissions() {
      let permissions = []
      for (const role of this.form.roles) {
        permissions = [...permissions, ...role.permissions]
      }

      const permissionsDescription = permissions.map((permission) => {
        return permission.description
      })

      this.selectedPermissions = uniq(permissionsDescription)
      this.notSelectedPermissions = difference(
        this.allPermissions,
        this.selectedPermissions
      )
    }
  }
}
</script>

<style lang="scss">
.compare-passwords {
  position: relative;
  top: -10px;
}

.equal-passwords {
  color: #3c9d74;
}

.different-passwords {
  color: #ef5b5b;
}

.permissions-container {
  background-color: #f5f6f7;
  border: 1px solid #f1f2f4;
  margin: 0 1px;
  padding: 10px 20px;
}

.permissions-title {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 10px;
}

.selected-permissions-description {
  display: inline-block;
  margin-bottom: 5px;
  color: #3c9d74;

  &:before {
    content: '\f058';
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 400;
    margin-right: 5px;
  }
}
.not-selected-permissions-description {
  display: inline-block;
  margin-bottom: 5px;
  color: #bdbdbd;

  &:before {
    content: '\f057';
    font-family: 'Font Awesome 5 Pro';
    font-style: normal;
    font-weight: 400;
    margin-right: 5px;
  }
}
</style>
